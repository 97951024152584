import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../../util/reactIntl';
import image from './main.svg';
import Img1 from './pet_2.jpg';
import Img2 from './pet_1.png';
import Img3 from './pet_3.jpg';
import Img4 from './pet_4.png';

import css from './SecondSection.module.css';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const SecondSectionComponent = props => {
  let language = 'PL';
  if (typeof window !== 'undefined') {
    language = window.localStorage.getItem('lang');
  }
  const listItems =
    language === 'EN'
      ? {
        left: [
          'We verify our sitters and their profiles..',
          'Each booking is protected by Pethomer Guarantee Program.',
          'We know our Sitters and their personal data.',
          'You can a meet and greet with your Sitter before booking, just to make sure they like each other.',
        ],
      }
      : {
        left: [
          'Sprawdzamy Opiekunów i weryfikujemy ich profile.',
          'Każda rezerwacja objęta jest Programem Ochrony Pethomer.',
          'Znamy naszych Opiekunów i ich dane osobowe.',
          'Możesz umówić się z Opiekunem na spotkanie zapoznawcze przed przekazaniem mu zwierzaka, aby być pewnym, że się polubią.',
        ],
      };

  return (
    <div className={css.sectionWrapper}>
      <div className={css.column}>
        <div >
          <div className={css.titleWrapper2}>
            <img src={image} style={{ marginLeft: '0.5rem' }} />
            <span className={css.cardTitle}>
              <FormattedMessage id="SecondSection.firstCard" />
            </span>
          </div>
          <ul>
            {listItems.left.map(e => (
              <li className={css.listItem}>
                <svg
                  className={css.checkBullets}
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13.5" cy="13.5" r="12.5" stroke="var(--secondary-500)" stroke-width="2" />
                  <path d="M7.5 13.5L12.8468 18L20.25 7.5" stroke="var(--secondary-500)" stroke-width="2" />
                </svg>
                <div>{e}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={css.grid}>
        <div
          className={css.card}
          onClick={() => {
            window.open(`${PUBLIC_URL}/s?address=Polska&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701&pub_amenities=has_all%3Aboarding&sort=pub_ratingScore`, '_top');
          }}
        >
          <div className={css.imageWrapper}>
            <img src={Img2} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="SecondSection.dogHotel" />
              </span>
              <span>
                <FormattedMessage id="SecondSection.dogHotelDescription" />
              </span>
            </div>
          </div>
        </div>

        <div
          className={css.card}
          onClick={() => {
            window.open(`${PUBLIC_URL}/s?address=Polska&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701&pub_amenities=has_all%3Afeeding&sort=pub_ratingScore`, '_top');
          }}

        >
          <div className={css.imageWrapper}>
            <img src={Img1} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="SecondSection.feeding" />
              </span>
              <span>
                <FormattedMessage id="SecondSection.feedingDescription" />
              </span>
            </div>
          </div>

        </div>


        <div
          className={css.card}
          onClick={() => {
            window.open(`${PUBLIC_URL}/s?address=Polska&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701&pub_amenities=has_all%3Awalking&sort=pub_ratingScore`, '_top');
          }}
        >
          <div className={css.imageWrapper}>
            <img src={Img3} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="SecondSection.walking" />
              </span>
              <span>
                <FormattedMessage id="SecondSection.walkingDescription" />
              </span>
            </div>
          </div>

        </div>
        <div
          className={css.card}
          onClick={() => {
            window.open(`${PUBLIC_URL}/s?address=Polska&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701&pub_amenities=has_all%3Adaycare&sort=pub_ratingScore`, '_top');
          }}
        >
          <div className={css.imageWrapper}>
            <img src={Img4} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="SecondSection.dogDayCare" />
              </span>
              <span>
                <FormattedMessage id="SecondSection.dogDayCareDescription" />
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const { bool, object } = PropTypes;

SecondSectionComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SecondSection = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(SecondSectionComponent);

export default SecondSection;
