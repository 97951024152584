import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { FormattedMessage } from '../../../util/reactIntl';
import Img1 from './ee1.png';
import Img2 from './ee5.png';
import Img3 from './ee6.png';


import css from './ThirdSection.module.css';
import { Button } from '../../../components';

export const ThirdSectionComponent = props => {
  return (
    <div
      className={css.sectionContainer}
    >
      {/* <div className={css.sectionWrapper2}>
        <div style={{ width: '100%' }}>
          <p className={css.header}>
            <FormattedMessage id="ThirdSection.title" />
          </p>
        </div>
      </div> */}

      <div className={css.grid}>
        {/* <div
          className={css.card}
          style={{
            backgroundImage: `url(${Img1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="ThirdSection.search" />
              </span>
              <span>
                <FormattedMessage id="ThirdSection.searchDescription" />
              </span>
            </div>
          </div>
        </div> */}
        <div className={css.card}>
          <div className={css.imageWrapper}>
            <img src={Img1} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="ThirdSection.search" />
              </span>
              <span>
                <FormattedMessage id="ThirdSection.searchDescription" />
              </span>
            </div>
          </div>
        </div>

        <div className={css.card}>
          <div className={css.imageWrapper}>
            <img src={Img2} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="ThirdSection.book" />
              </span>
              <span>
                <FormattedMessage id="ThirdSection.bookDescription" />
              </span>
            </div>
          </div>
        </div>

        <div className={css.card}>
          <div className={css.imageWrapper}>
            <img src={Img3} className={css.image} />
          </div>
          <div className={css.titleWrapper}>
            <div className={css.cardRight}>
              <span className={css.cardTitle}>
                <FormattedMessage id="ThirdSection.enjoy" />
              </span>
              <span>
                <FormattedMessage id="ThirdSection.enjoyDescription" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className={css.heroButton}
          onClick={() => {
            props.history.push('/s');
          }}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </Button>
      </div>
    </div>
  );
};

const { bool, object } = PropTypes;

ThirdSectionComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ThirdSection = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ThirdSectionComponent);

export default ThirdSection;
