import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Button, Modal } from '../../../components';
import { addSendgridContact } from '../../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import css from './ThirdSection.module.css';

const NewsletterSuccessModal = (props) => {
  return <Modal
    id="NewsletterSuccessModal"
    isOpen={props.isOpen}
    onClose={() => {
      props.onClose();
    }}
    usePortal
    onManageDisableScrolling={() => { }}
    closeButtonMessage={"Close"}
  >
    <div className={css.modalTitle}>
      🎉 Dziękujemy za zapisanie się do newslettera!
    </div>
    <div className={css.modalDescription}>
      Teraz będziesz na bieżąco z najnowszymi informacjami i ofertami. Sprawdź swoją skrzynkę e-mail! 📩
    </div>
  </Modal>
}

export const NewsletterSectionComponent = props => {
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState();


  return (
    <>
      <NewsletterSuccessModal isOpen={isModalOpen} onClose={() => {
        setIsModalOpen(false);
      }} />
      <div className={css.section}>
        <div className={css.container}>
          <div className={css.leftWrapper} >
            <p className={css.header}>
              <FormattedMessage id="NewsletterSection.title" />
            </p>
            <p className={css.header2}>
              <FormattedMessage id="NewsletterSection.title2" />
            </p>
          </div>

          <div className={css.rightWrapper} >
            <div className={css.inputGroup}>
              <input
                className={css.input}
                placeholder='E-mail'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Button
                className={css.heroButton}
                isProgress={loading}
                onClick={() => {
                  if (!email) {
                    setErrors({ email: 'Email jest wymagany' });
                    return;
                  }
                  if (!email.includes('@')) {
                    setErrors({ email: 'Niepoprawny adres e-mail' });
                    return;
                  }
                  if (!checked) {
                    setErrors({ email: 'Zgoda na przetwarzanie danych jest wymagana' });
                    return;
                  }
                  if (loading) return;
                  setLoading(true);
                  addSendgridContact({
                    email,
                    listType: 'newsletter',
                    customFields: {
                      newsletter_consent: checked ? 'false' : 'true',
                    }
                  })
                    .then(() => {
                      setIsModalOpen(true);
                    })
                    .finally(() => {
                      setLoading(false);
                      setEmail('');
                      setChecked(false);
                      setErrors(null);
                    }
                    );
                }}
              >
                <FormattedMessage id="NewsletterSection.button.label" />
              </Button>
            </div>
         
            <div className={css.checkboxGroup}>
              <input
                type='checkbox'
                className={css.checkbox}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <div className={css.checkboxLabel}>
                <FormattedMessage id="NewsletterSection.checkbox.description" />
              </div>
            </div>
            <div className={css.errorLabel}>
              {errors?.email && <div className={css.error}>{errors.email}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const { bool, object } = PropTypes;

NewsletterSectionComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NewsletterSection = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(NewsletterSectionComponent);

export default NewsletterSection;
